/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
import { useState } from 'react';
import PropTypes from 'prop-types';
// material
import { Switch, FormControl, FormGroup, FormControlLabel, FormLabel } from '@material-ui/core';
import InfoToolTip from '../../common/InfoToolTip';

// the regex rule to validate the rack name. this ensures the name is all lowercase, starts & ends with alphanumeric, and contains no spaces
const automaticRackStopToolTip = `This will automatically turn off your virtual rack after being inactive for 2 weeks to save cloud compute resources. You can power the rack back on from the rack's details page when you are ready to use it again. Disabling this feature will subject your team to weekly email reminders after the virtual rack becomes inactive.`;

const styles = {
  switchLabel: { justifyContent: 'start', marginLeft: 0 }
};

AutomaticRackStop.propTypes = {
  rack: PropTypes.object,
  updateRack: PropTypes.func
};

export default function AutomaticRackStop({ rack, updateRack }) {
  const [autoRackStop, setAutoRackStop] = useState(rack.rack.autoRackStop.enabled);

  const handleAutoRackStopChange = (event) => {
    const enabled = event.target.checked;
    setAutoRackStop(enabled);
    const rackCopy = { ...rack };
    rackCopy.rack.autoRackStop.enabled = enabled;
    updateRack(rackCopy);
  };

  return (
    <FormControl>
      <FormLabel>
        Automatic Rack Stop <InfoToolTip title={automaticRackStopToolTip} />
      </FormLabel>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch defaultChecked checked={autoRackStop} onChange={handleAutoRackStopChange} />
          }
          label={autoRackStop ? 'On' : 'Off'}
          sx={styles.switchLabel}
        />
      </FormGroup>
    </FormControl>
  );
}
