import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// material
import {
  CardContent,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Stack,
  Typography
} from '@material-ui/core';
import { Card } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { CancelButton } from '.';

const deleteSteps = [
  {
    value: 'deleting namespace',
    step: 0,
    label: 'Deleting Resources',
    statuses: ['deleting'],
    step_complete_statuses: ['deleted'],
    status_details: ['deleting VMs', 'deleting namespace', 'tearing down network'],
    current_statuses: []
  },
  {
    value: 'completed',
    step: 1,
    label: 'Deleted',
    statuses: [],
    step_complete_statuses: [],
    status_details: [],
    current_statuses: []
  }
];
const createSteps = [
  {
    step: 0,
    label: 'Deploying',
    statuses: ['provisioning', 'provisioned', 'deploying', 'deployed'],
    step_complete_statuses: ['provisioned', 'deployed'],
    status_details: [
      'setting up network',
      'deploying VMs',
      'waiting for gateway VM to be available'
    ],
    current_statuses: []
  },
  {
    step: 1,
    label: 'Loading',
    statuses: ['loading', 'loaded'],
    step_complete_statuses: ['loaded'],
    status_details: [
      'downloading components',
      'initializing LMS',
      'activating components',
      'verifying if bootstrap is successfull',
      'rebooting master LRU',
      'downloading kit',
      'activating kit',
      'verifying kit load successful',
      'waiting for lms to restart',
      'rebooting master LRU',
      'Waiting for software to be in system steady state'
    ],
    current_statuses: []
  },
  {
    step: 2,
    label: 'Configuring',
    statuses: [],
    step_complete_statuses: ['configured'],
    status_details: ['configuring rack running state', 'configuring rack connectivity'],
    current_statuses: []
  },
  {
    step: 3,
    label: 'Ready',
    statuses: [],
    step_complete_statuses: [],
    status_details: [],
    current_statuses: []
  }
];

// ----------------------------------------------------------------------
function getActiveStep(statuses, steps) {
  // determines which step is the active step for the rack
  if (statuses.length > 0) {
    const lastStatus = statuses[statuses.length - 1];
    // loop backwards thru step statuses and look for the one that matches the lastStatus
    for (let i = steps.length - 1; i > -1; i -= 1) {
      if (
        steps[i].current_statuses.length > 0 &&
        JSON.stringify(steps[i].current_statuses[steps[i].current_statuses.length - 1]) ===
          JSON.stringify(lastStatus)
      ) {
        return steps[i].step;
      }
    }
  }
  return 0;
}

function getStatusStepNumber(status, steps, lastStep) {
  // returns the step number for the status given
  for (let i = 0; i < steps.length; i += 1) {
    // check if the statuses match
    if (steps[i].statuses.includes(status.metadata.rack_status.status)) {
      return steps[i].step;
    }
    // check if the status details match
    if (steps[i].status_details.includes(status.metadata.rack_status.details)) {
      return steps[i].step;
    }
  }
  return lastStep;
}

function isStepCompleted(step, steps, runningState) {
  // determines which step was completed as some steps may not be required (bootstrapping/loading)
  if (step.label === 'Ready' || step.label === 'Deleted') {
    // if step is Ready, make sure rack is not stopped
    if (step.label === 'Ready' && runningState === 'stopped') {
      return false;
    }
    // check all steps and make sure the last status is completed or empty
    for (let i = 0; i < steps.length; i += 1) {
      if (
        steps[i].current_statuses.length > 0 &&
        steps[i].current_statuses[steps[i].current_statuses.length - 1].status !== 'completed' &&
        !steps[i].step_complete_statuses.includes(
          steps[i].current_statuses[steps[i].current_statuses.length - 1].metadata.rack_status
            .status
        )
      ) {
        return false;
      }
    }
    return true;
  }

  if (step.current_statuses.length > 0) {
    // check if complete status should be completed
    if (
      (step.current_statuses.length > 0 &&
        step.current_statuses[step.current_statuses.length - 1].status === 'completed') ||
      step.step_complete_statuses.includes(
        step.current_statuses[step.current_statuses.length - 1].metadata.rack_status.status
      )
    ) {
      return true;
    }
  }

  return false;
}

function isStepInProgress(step) {
  // determines which step is still in progress
  if (step.current_statuses.length > 0) {
    // steps won't be in progress if last status is 'completed'
    if (
      step.current_statuses[step.current_statuses.length - 1].status !== 'completed' &&
      step.current_statuses[step.current_statuses.length - 1].status !== 'interrupted' &&
      !step.step_complete_statuses.includes(
        step.current_statuses[step.current_statuses.length - 1].metadata.rack_status.status
      )
    ) {
      return true;
    }
  }
  return false;
}

function isStepError(step) {
  // determines if step should have an error status
  if (step.current_statuses.length > 0) {
    // check if step's last status is interrupted
    return step.current_statuses[step.current_statuses.length - 1].status === 'interrupted';
  }
  return false;
}

function getLatestStepError(step) {
  // retrieves the last status that has the error message
  const lastStatus = step.current_statuses[step.current_statuses.length - 1];
  if (lastStatus.metadata.error === '') {
    // if the main error is empty (like when cancelled), return the rack_status.status instead
    return lastStatus.metadata.rack_status.status;
  }
  return lastStatus.metadata.error;
}

function getLatestStepStatus(step) {
  // retrieves the last status details
  return step.current_statuses[step.current_statuses.length - 1].metadata.rack_status.details;
}

function isDeleting(statuses) {
  return statuses.some((status) => status.metadata.rack_status.status === 'deleting');
}

function getSteps(statuses) {
  // if there's a status with delete on it, then use the delete steps
  if (statuses) {
    if (isDeleting(statuses)) {
      return deleteSteps;
    }
  }
  return createSteps;
}

function populateSteps(steps, statuses) {
  // populates the steps's current_statuses with the statuses
  // loop through each status
  let stepNumber = 0;
  statuses.forEach((status) => {
    stepNumber = getStatusStepNumber(status, steps, stepNumber);
    steps[stepNumber].current_statuses.push(status);
    // see if the step is completed
    // steps[stepNumber].completed = status.
  });
}

function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

StatusTimeline.propTypes = {
  rackDetails: PropTypes.object
};

export default function StatusTimeline({ rackDetails }) {
  const [steps, setSteps] = useState(createSteps);
  // determine which steps to use for status timeline
  useEffect(() => {
    // deep copy old steps
    const newSteps = deepCopy(getSteps(rackDetails.statuses));
    populateSteps(newSteps, rackDetails.statuses);
    // populate the steps with the new statuses coming in
    setSteps(newSteps);
  }, [rackDetails]);

  return (
    <Card>
      <Stack m={2} mx={3} direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Rack Status</Typography>
        <CancelButton rackDetails={rackDetails} />
      </Stack>

      <CardContent>
        <Stepper
          sx={{ marginY: 2, marginX: -5 }}
          activeStep={getActiveStep(rackDetails.statuses, steps)}
          alternativeLabel
        >
          {steps.map((step, i) => {
            const labelProps = {};
            let stepDetails = '';
            const inProgress = isStepInProgress(step);
            if (isStepError(step)) {
              labelProps.error = true;
              stepDetails = getLatestStepError(step);
            }
            if (inProgress) {
              stepDetails = getLatestStepStatus(step);
            }
            return (
              <Tooltip key={i} title={stepDetails} arrow placement="top">
                <Step key={i} completed={isStepCompleted(step, steps, rackDetails.running.state)}>
                  <StepLabel {...labelProps}>
                    {step.label}
                    {inProgress && (
                      <CircularProgress
                        disableShrink
                        color="primary"
                        size={36}
                        sx={{
                          position: 'absolute',
                          top: '20%',
                          left: '50%',
                          zIndex: 1,
                          margin: '-18px 0px 0px -18px'
                        }}
                      />
                    )}
                  </StepLabel>
                </Step>
              </Tooltip>
            );
          })}
        </Stepper>
      </CardContent>
    </Card>
  );
}
