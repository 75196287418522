import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  TextField,
  Grid,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  Chip,
  Stack
} from '@material-ui/core';
import useVrackApi from '../../../hooks/useVrackApi';
import TailNumber from './TailNumber';
import { useIsFeatureFlagEnabled } from '../../../utils/FeatureFlags';

const defaultXIDHelperText =
  'Aircraft unique identifier that is required for GCS Connectivity. Only LAB XIDs are permitted for testing.';
const xidErrorText =
  'Invalid XID. Please make sure that the XID is not used and in a valid LAB XID Range';

// updates the rack object's xid
function updateRackXID(rack, updateRack, xid) {
  const rackCopy = { ...rack };
  rackCopy.rack.xid = xid;
  updateRack(rackCopy);
}

// checks if this is a GCS rack (bc03 enabled)
function isGCSEnabled(rack) {
  return rack.lrus.bc03?.enabled;
}

// validates if the xid is valid from the vrack api
async function validateXID(vrackApiValidateXID, xid) {
  try {
    await vrackApiValidateXID({
      url: `/racks/connectivity/xid/${xid}`
    });
    return true;
  } catch (error) {
    console.error('Error validating xid', error);
    return false;
  }
}

Connectivity.propTypes = {
  updateRack: PropTypes.func,
  rack: PropTypes.object,
  setNextButtonDisabled: PropTypes.func
};

const groundConnectivitySupported = (rack) =>
  'xid' in rack.rack &&
  rack.rack.xid !== 0 &&
  'tailNumber' in rack.rack &&
  rack.rack.tailNumber !== '' &&
  !rack.rack.simulatedServices.modem.enabled;

const cellularConnectivitySupported = (rack) =>
  !rack.rack.simulatedServices.groundConnectivity.enabled;

export default function Connectivity({ rack, updateRack, setNextButtonDisabled }) {
  const [xid, setXID] = useState(rack.rack.xid || '');
  const [xidError, setXIDError] = useState(false);
  const [validTail, setValidTail] = useState(false);
  const [xidHelperText, setXIDHelperText] = useState(defaultXIDHelperText);
  const { fetchData: vrackApiValidateXID } = useVrackApi('get', '/racks/connectivity/xid');
  const isTailNumberEnabled = useIsFeatureFlagEnabled('tail_number');
  const xidEnabled = useIsFeatureFlagEnabled('xid');
  const gcsEnabled = useIsFeatureFlagEnabled('ground_connectivity') && isGCSEnabled(rack.rack);
  // helper functions to extract values from the rack object for initialization
  const setConnectivity = (event) => {
    // updates the rack object's gateway property with the new values
    const rackCopy = { ...rack };
    if (event.target.name.includes('cellmodem')) {
      rackCopy.rack.simulatedServices.modem.enabled = event.target.value === 'true';
    } else if (event.target.name.includes('ground')) {
      rackCopy.rack.simulatedServices.groundConnectivity.enabled = event.target.value === 'true';
    }
    updateRack(rackCopy);
  };
  // event handler when rack xid input value changes
  const handleRackXIDChange = async (event) => {
    const xid = parseInt(event.target.value, 10) || '';
    setXID(xid);

    if (xid !== '') {
      if (await validateXID(vrackApiValidateXID, xid)) {
        updateRackXID(rack, updateRack, xid);
        setXIDError(false);
      } else {
        // failed to validate the tail number
        setXIDError(true);
        updateRackXID(rack, updateRack, 0);
      }
    } else {
      updateRackXID(rack, updateRack, 0);
      setXIDError(false);
    }
  };
  const validateInputs = () => {
    if (!validTail && !xidError) {
      setNextButtonDisabled(false);
    } else {
      setNextButtonDisabled(true);
    }
  };
  useEffect(() => {
    validateInputs();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [xidError, validTail]);
  // updates the helper text of the input when error is updated
  useEffect(() => {
    if (xidError) {
      setXIDHelperText(xidErrorText);
    } else {
      setXIDHelperText(defaultXIDHelperText);
    }
  }, [xidError]);

  useEffect(() => {
    const rackCopy = { ...rack };
    let change = false;
    if (!gcsEnabled) {
      if (rackCopy.rack.xid && rackCopy.rack.xid !== 0) {
        rackCopy.rack.xid = 0;
        change = true;
      }
      if (rackCopy.rack.simulatedServices.groundConnectivity.enabled === true) {
        rackCopy.rack.simulatedServices.groundConnectivity.enabled = false;
        change = true;
      }
    }
    if (change) {
      updateRack(rackCopy);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <FormControl component="fieldset">
      <Typography variant="h6">Connectivity</Typography>
      <Typography variant="caption">
        These settings are for configuring the various connectivity options for your rack. <br />
        Please note that either the GCS Ku or Cellular network can be enabled at a time.
      </Typography>

      <Grid mt={1} sx={{ paddingLeft: 0 }} spacing={2} container item sm={12}>
        <Grid item xs={12} sm={6} md={3}>
          {isTailNumberEnabled && (
            <TailNumber
              rack={rack}
              updateRack={updateRack}
              setNextButtonDisabled={setNextButtonDisabled}
              setValidTail={setValidTail}
            />
          )}
        </Grid>
        {gcsEnabled && xidEnabled && (
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              data-testid="xid"
              helperText={xidHelperText}
              value={xid}
              onChange={handleRackXIDChange}
              label="XID"
              error={xidError}
            />
          </Grid>
        )}
        {gcsEnabled && xidEnabled && (
          <Grid item sm={12}>
            <FormControl disabled={!groundConnectivitySupported(rack)} component="fieldset">
              <Stack direction="row" alignItems="center">
                <Typography variant="subtitle2">GCS Ku Network</Typography>
                <Chip sx={{ m: 0.5 }} color="success" size="small" label="beta" />
              </Stack>

              <Typography variant="caption">
                Simulates PAC GCS Ku ground connectivity. You must set XID and tail number above to
                use this network.
              </Typography>
              <RadioGroup
                sx={{ marginTop: 0.5 }}
                aria-label="ku-network"
                value={rack.rack.simulatedServices.groundConnectivity.enabled.toString()}
              >
                <FormControlLabel
                  value="true"
                  label="Enabled"
                  name="ground-enable"
                  onChange={setConnectivity}
                  control={<Radio />}
                />
                <FormControlLabel
                  value="false"
                  label="Disabled"
                  name="ground-disable"
                  onChange={setConnectivity}
                  control={<Radio />}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        )}
        <Grid item sm={12}>
          <FormControl disabled={!cellularConnectivitySupported(rack)} component="fieldset">
            <Typography variant="subtitle2">Cellular Network</Typography>
            <Typography variant="caption">
              Simulates cellular connectivity to the rack. Requires routing and whitelisting
              configuration in the database.
            </Typography>
            <RadioGroup
              sx={{ marginTop: 0.5 }}
              aria-label="cellular-network"
              value={rack.rack.simulatedServices.modem.enabled.toString()}
            >
              <FormControlLabel
                value="true"
                label="Enabled"
                name="cellmodem-enable"
                onChange={setConnectivity}
                control={<Radio />}
              />
              <FormControlLabel
                value="false"
                label="Disabled"
                name="cellmodem-disable"
                onChange={setConnectivity}
                control={<Radio />}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </FormControl>
  );
}
