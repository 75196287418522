import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  InputLabel,
  Grid,
  Typography,
  Slider
} from '@material-ui/core';

const cpuMarks = [
  {
    value: 2000,
    label: '2'
  },
  {
    value: 3000,
    label: '3'
  },
  {
    value: 4000,
    label: '4'
  },
  {
    value: 5000,
    label: '5'
  },
  {
    value: 6000,
    label: '6'
  },
  {
    value: 7000,
    label: '7'
  },
  {
    value: 8000,
    label: '8'
  }
];
const memoryMarks = [
  {
    value: 2000,
    label: '2000'
  },
  {
    value: 3000,
    label: '3000'
  },
  {
    value: 4000,
    label: '4000'
  },
  {
    value: 5000,
    label: '5000'
  },
  {
    value: 6000,
    label: '6000'
  },
  {
    value: 7000,
    label: '7000'
  },
  {
    value: 8000,
    label: '8000'
  }
];

// maps the disk size to the appropriate total M values for the particular LRU
const diskSizeMap = {
  cs: { small: 80000, medium: 130000, large: 180000, original: 330000 },
  is: { small: 150000, medium: 300000, large: 450000, original: 1000000 },
  next_is: { small: 150000, medium: 300000, large: 450000, original: 1000000 },
  cs4: { small: 250000, medium: 500000, large: 750000, original: 1400000 },
  fs05: { small: 250000, medium: 500000, large: 750000, original: 1400000 },
  next_cs4: { small: 150000, medium: 300000, large: 450000, original: 1000000 },
  ex3_nc: { small: 66000, medium: 116000, large: 166000, original: 816000 },
  next_nc: { small: 180000, medium: 250000, large: 500000, original: 1000000 },
  bc03: { small: 66000, medium: 216000, large: 416000, original: 816000 },
  smartmonitor: { small: 50000, medium: 100000, large: 150000, original: 200000 }
};

const resourceSizeMap = {
  small: 2000,
  medium: 4000,
  large: 6000,
  original: 8000
};

// mapping of resources for lrus with custom resource values
const customLruResourcesSizeMap = {
  smartmonitor: {
    small: 4000,
    medium: 6000,
    large: 8000,
    original: 8000
  }
};

const sizes = ['small', 'medium', 'large', 'original', 'custom'];
export const diskSizes = ['small', 'medium', 'large', 'original'];

const diskSizeMarkMap = {
  small: 1,
  medium: 2,
  large: 3,
  original: 4
};

const diskMarkSizeMap = {
  1: 'small',
  2: 'medium',
  3: 'large',
  4: 'original'
};

function getDiskMarks(lru) {
  return [
    { value: 1, label: diskSizeMap[lru].small / 1000 },
    { value: 2, label: diskSizeMap[lru].medium / 1000 },
    { value: 3, label: diskSizeMap[lru].large / 1000 },
    { value: 4, label: diskSizeMap[lru].original / 1000 }
  ];
}

function getDiskSize(diskSize) {
  return diskSizeMarkMap[diskSize];
}

function getResourcesSize(lru, resourceSize) {
  return lru in customLruResourcesSizeMap
    ? customLruResourcesSizeMap[lru][resourceSize]
    : resourceSizeMap[resourceSize];
}

LRUSize.propTypes = {
  updateCurrentLRU: PropTypes.func,
  currentLRU: PropTypes.object
};

export function LRUSize({ updateCurrentLRU, currentLRU }) {
  const setSize = (event) => {
    const s = event.target.value;
    // if not custom, update the disk, memory, and cpu size accordingly
    if (s !== 'custom') {
      updateCurrentLRU((prevState) => ({
        ...prevState,
        size: s,
        diskSize: s,
        memory: getResourcesSize(currentLRU.value, s),
        cpu: getResourcesSize(currentLRU.value, s)
      }));
    } else {
      updateCurrentLRU((prevState) => ({
        ...prevState,
        size: s
      }));
    }
  };

  const setDiskSize = (event) => {
    updateCurrentLRU((prevState) => ({
      ...prevState,
      diskSize: diskMarkSizeMap[event.target.value]
    }));
  };

  const setMemoryLimit = (event) => {
    updateCurrentLRU((prevState) => ({
      ...prevState,
      memory: event.target.value
    }));
  };

  const setCPULimit = (event) => {
    updateCurrentLRU((prevState) => ({
      ...prevState,
      cpu: event.target.value
    }));
  };

  const isCustom = () => currentLRU.size === 'custom';

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">Size</Typography>
      </Grid>
      <Grid container item ml={2} xs={12} rowSpacing={2}>
        <Grid item sm={12}>
          <FormControl>
            <InputLabel id="size-label">Instance Size</InputLabel>
            <Select
              labelId="size-label"
              label="Instance Size"
              value={currentLRU.size}
              onChange={setSize}
              sx={{ width: 230 }}
            >
              {sizes.map((el, i) => (
                <MenuItem key={i} value={el}>
                  {el} {el === 'small' ? '(default)' : ''}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              The instance size will affect the disk size and resource limits
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sx={{ ml: 4 }}>
          <Typography variant="subtitle2">Disk Size (GB)</Typography>
          <Slider
            aria-label="DiskSize"
            sx={{ marginLeft: 1.2, width: '90%', maxWidth: 400 }}
            marks={getDiskMarks(currentLRU.value)}
            max={getDiskMarks(currentLRU.value)[getDiskMarks(currentLRU.value).length - 1].value}
            min={getDiskMarks(currentLRU.value)[0].value}
            step={null}
            value={getDiskSize(currentLRU.diskSize)}
            onChange={setDiskSize}
            disabled={!isCustom()}
            size="small"
          />
          <Typography variant="subtitle2">CPU Limit (# cores)</Typography>
          <Slider
            aria-label="CPULimit"
            sx={{ marginLeft: 1.2, width: '90%', maxWidth: 400 }}
            valueLabelDisplay="auto"
            marks={cpuMarks}
            max={cpuMarks[cpuMarks.length - 1].value}
            min={cpuMarks[0].value}
            step={null}
            value={currentLRU.cpu}
            onChange={setCPULimit}
            disabled={!isCustom()}
            size="small"
          />
          <Typography variant="subtitle2">Memory Limit (MB)</Typography>
          <Slider
            aria-label="MemoryLimit"
            sx={{ marginLeft: 1.2, width: '90%', maxWidth: 400 }}
            valueLabelDisplay="auto"
            marks={memoryMarks}
            max={memoryMarks[memoryMarks.length - 1].value}
            min={memoryMarks[0].value}
            step={500}
            value={currentLRU.memory}
            onChange={setMemoryLimit}
            disabled={!isCustom()}
            size="small"
          />
        </Grid>
      </Grid>
    </>
  );
}
