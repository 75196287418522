/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
import { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import filesize from 'filesize';
// material
import { Box, Typography, LinearProgress, Fade, Grid, Stack } from '@material-ui/core';
// components
import { useDropzone } from 'react-dropzone';

const baseStyle = {
  height: '200px',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  transition: 'border .3s ease-in-out'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const dropzoneErrEmpty = `You must provide a file to upload`;

LoadableDropzone.propTypes = {
  setDropzoneError: PropTypes.func,
  fileDropzoneError: PropTypes.bool,
  setAcceptedFiles: PropTypes.func,
  acceptedFiles: PropTypes.object,
  uploadProgress: PropTypes.number,
  setUploadProgress: PropTypes.func,
  setFilename: PropTypes.func
};

export default function LoadableDropzone({
  setDropzoneError,
  fileDropzoneError,
  setAcceptedFiles,
  acceptedFiles,
  setFilename,
  uploadProgress,
  setUploadProgress
}) {
  const [activateFade, setActivateFade] = useState(false);

  const onDrop = useCallback(
    (files) => {
      setDropzoneError(false);
      if (files.length > 0 && uploadProgress === 0) {
        setActivateFade(true);
        setAcceptedFiles(files[0]);
        setFilename(files[0].name);
      }
    },
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [acceptedFiles]
  );

  useEffect(() => {
    if (uploadProgress === 100) {
      setTimeout(() => {
        setActivateFade(!activateFade);
        setUploadProgress(0);
      }, 750);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [uploadProgress]);

  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'application/x-gzip': ['.gz', '.tgz'],
      'application/zip': ['.zip'],
      'application/x-tar': ['.tar']
    }
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragReject || fileDropzoneError ? rejectStyle : {}),
      ...(isDragAccept ? acceptStyle : {})
    }),
    [isDragReject, isDragAccept, fileDropzoneError]
  );

  return (
    <>
      <Box {...getRootProps({ style, borderColor: 'red' })}>
        <input {...getInputProps()} />
        <Grid
          container
          sx={{ height: '100%' }}
          item
          alignItems="center"
          justifyContent="center"
          xs={12}
        >
          {acceptedFiles ? (
            <Fade
              in={activateFade}
              onExited={() => {
                setAcceptedFiles(null);
              }}
            >
              <Grid container alignItems="center" justifyContent="center" item xs={12}>
                <Grid item dog={2} xs={12} sm={3} md={2}>
                  <Typography variant="subtitle1">
                    {acceptedFiles.name} - {filesize(acceptedFiles.size)}
                  </Typography>
                </Grid>
                <Grid ml={0} item xs={12} sm={6} md={6}>
                  <Stack alignItems="center" spacing={1} direction="row">
                    <LinearProgress
                      sx={{ width: '95%' }}
                      variant="determinate"
                      value={uploadProgress}
                    />
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                      uploadProgress
                    )}%`}</Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Fade>
          ) : (
            <Typography sx={{ textAlign: 'center' }}>
              Drag and drop loadable here, or click to select files
              <br />
              (Only .tar, .tgz. and .zip files are accepted)
            </Typography>
          )}
        </Grid>
      </Box>
      {fileDropzoneError && (
        <Typography sx={{ paddingLeft: 1.5, color: '#FF4842' }} variant="caption">
          {dropzoneErrEmpty}
        </Typography>
      )}
    </>
  );
}
