// material
import { Grid, Container, Typography, Stack, Box, Divider } from '@material-ui/core';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { set } from '../store/slices/identitySlice';

// components
import Page from '../components/Page';
import {
  StatusTimeline,
  GeneralInfo,
  LRUInfo,
  LoadingInfo,
  Connectivity,
  RackNotFound,
  DeleteRackButton,
  ConnectRackButton,
  SetRackStateButton
} from '../components/rack/details';
import { getIDToken } from '../components/authentication/login/amplify';

function addCompletedStatus(rack) {
  if (rack.statuses.length > 0) {
    const lastStatus = rack.statuses[rack.statuses.length - 1];

    if (lastStatus.metadata.status !== 'completed') {
      // Add a final status to rack indicating deletion is completed
      const rackCopy = { ...rack };
      rackCopy.statuses.push({
        id: '',
        metadata: { error: '', notes: '', rack: '', rack_status: { details: '', status: '' } },
        status: 'completed'
      });
      return rackCopy;
    }
  }

  return rack;
}

function isJobInProgress(rack) {
  if (rack.statuses.length > 0) {
    return rack.statuses[rack.statuses.length - 1].status === 'inprogress';
  }
  return false;
}

// const representing the substring returned in errors when a rack does not exist
const RACK_NOT_FOUND_SUBSTR = 'no rack was found matching';

// ----------------------------------------------------------------------
export default function RackDetails() {
  const navigate = useNavigate();
  const { rackname } = useParams();
  const [rack, setRack] = useState({ running: { state: 'stopped' }, statuses: [] });
  const dispatch = useDispatch();

  function rackExists(rack, error) {
    if (error.response && 'data' in error.response && 'message' in error.response.data) {
      if (error.response.data.message.includes(RACK_NOT_FOUND_SUBSTR)) {
        if (!rack.name) {
          return false;
        }
        // add completion status and return true
        setRack(addCompletedStatus(rack));
        return true;
      }
    }
    return true;
  }

  const getRackDetails = async (rackname, setRack, navigate) => {
    // call api for get rack details
    const jwt = await getIDToken();
    if (jwt) {
      axios
        .get(`${process.env.REACT_APP_API}/racks/name/${rackname}`, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        })
        .then((response) => {
          setRack(response.data[0]);
          dispatch(set(response.data[0].provisioner_identity));
        })
        .catch((error) => {
          // Check if error is due to the rack not existing and if so, redirect to 404 page
          if (!rackExists(rack, error)) {
            navigate('/404', { replace: true });
            return;
          }
          // Check if error is due to not being authorized to view the page
          if (error.response && error.response.status === 401) {
            navigate('/401', { replace: true });
            return;
          }
          console.error('failed to get rack details', error);
        });
    } else {
      console.error('failed to get JWT, user needs to login');
    }
  };

  useEffect(() => {
    getRackDetails(rackname, setRack, navigate);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getRackDetails(rackname, setRack, navigate);
    }, 5000);
    return () => clearInterval(interval);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [rackname, rack]);

  if (rack) {
    return (
      <Page title={`${rackname} Details`}>
        <Container maxWidth={false}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            marginBottom={5}
          >
            <Box ml={2}>
              <Typography variant="h3">VRack Details</Typography>
              <Divider />
              <Box sx={{ marginTop: 1 }}>
                <Typography
                  variant="subtitle2"
                  sx={{
                    textTransform: 'uppercase',
                    fontWeight: 100,
                    letterSpacing: 3,
                    opacity: 0.7,
                    display: 'inline-block',
                    marginRight: 1
                  }}
                >
                  {rackname}
                </Typography>
              </Box>
            </Box>
            <Stack direction="row" spacing={2}>
              <ConnectRackButton rackDetails={rack} />
              <SetRackStateButton rackDetails={rack} disabled={isJobInProgress(rack)} />
              <DeleteRackButton
                rack={rack.name}
                environment={rack.cluster}
                disabled={isJobInProgress(rack)}
              />
            </Stack>
          </Stack>
          <Grid container spacing={3}>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={12}>
                <StatusTimeline rackDetails={rack} />
              </Grid>
              <Grid item xs={12}>
                <GeneralInfo rackDetails={rack} />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={7} lg={5}>
              <Connectivity rackDetails={rack} />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={7}>
              <LRUInfo rackDetails={rack} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <LoadingInfo rackDetails={rack} />
            </Grid>
          </Grid>
        </Container>
      </Page>
    );
  }
  return <RackNotFound rackname={rackname} />;
}
