import { Stack, Typography } from '@material-ui/core';

const version = '1.6.0';

VersionFooter.propTypes = {};

export default function VersionFooter() {
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" p={1}>
      <Typography variant="caption" sx={{ color: 'darkgrey' }}>
        v{version}
      </Typography>
    </Stack>
  );
}
