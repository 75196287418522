// material
import { Box, Stack } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import Guacamole from 'guacamole-common-js';
import crypto from 'crypto';
import { getIDToken } from '../components/authentication/login/amplify';

// dimensions of vsm vnc view
const vsmWidth = 1920;
const vsmHeight = 1080;

function encryptPayload(payload) {
  // encrypt the payload using the encryption cypher type and key
  const encType = 'AES-256-CBC';
  const encKey = 'MySuperSecretKeyForParamsToken12';

  const iv = crypto.randomBytes(16);
  const cipher = crypto.createCipheriv(encType, encKey, iv);

  let crypted = cipher.update(JSON.stringify(payload), 'utf8', 'base64');
  crypted += cipher.final('base64');

  const data = {
    iv: iv.toString('base64'),
    value: crypted
  };
  const base64Data = Buffer.from(JSON.stringify(data)).toString('base64');
  return base64Data;
}

function calculateScaleFactor() {
  // this function calculates how much the vsm view should be scaled by depending on the window dimensions

  // this is the container/div around the guacamole view
  const vsmContainer = document.getElementById('container');
  const scale = Math.min(
    vsmContainer.offsetWidth / Math.max(vsmWidth, 1),
    vsmContainer.offsetHeight / Math.max(vsmHeight, 1)
  );
  return scale;
}

function addKeyboardIntegration(guacClient) {
  // adds keyboard integration to vsm display to allow typing
  const keyboard = new Guacamole.Keyboard(document);
  keyboard.onkeydown = (keysym) => {
    guacClient.sendKeyEvent(1, keysym);
  };
  keyboard.onkeyup = (keysym) => {
    guacClient.sendKeyEvent(0, keysym);
  };
}

const addMouseIntegration = (guacClient) => {
  // adds mouse integration to vsm to allow clicking and swiping
  const mouse = new Guacamole.Mouse(guacClient.getDisplay().getElement());
  // update mouse to have proper scaled coordinates
  mouse.onmousedown = (mouseState) => {
    guacClient.sendMouseState(mouseState);
  };
  mouse.onmouseup = (mouseState) => {
    guacClient.sendMouseState(mouseState);
  };
  mouse.onmousemove = (mouseState) => {
    // need to translate mouse coordinates based on scaling factor of display
    mouseState.x /= calculateScaleFactor();
    mouseState.y /= calculateScaleFactor();
    guacClient.sendMouseState(mouseState);
  };
};

function getGuacamoleTunnelAddress() {
  // Get the appropriate guacamole tunnel address depending on the STAGE env
  switch (process.env.REACT_APP_STAGE) {
    case 'dev':
      return 'wss://guacamole.virtualrack-dev.nextcloud.aero:8822/';
    case 'test':
      return 'wss://guacamole.virtualrack-test.nextcloud.aero:8822/';
    default:
      return 'wss://guacamole.virtualrack.nextcloud.aero:8822/';
  }
}

const styles = {
  background: {
    background: 'darkgray',
    height: '100%'
  },
  vsmContainer: {
    height: '100%',
    backgroundColor: '#000',
    borderRadius: '15px',
    boxShadow: '0 5px 10px #888',
    border: '3px double grey'
  },
  vsm: { minHeight: `94%`, marginX: 3, backgroundColor: '#181818' },
  border: { height: '3%' },
  panasonicLogo: { height: '100%' }
};

export default function VSMConnect() {
  const tunnelWsAddress = getGuacamoleTunnelAddress();
  const { rackname } = useParams();

  const getEncryptedPayload = async () => {
    // payload to define initial payload for vrack to connect to guacamole
    const jwt = await getIDToken();
    if (jwt) {
      const guacConnectPayload = {
        token: jwt,
        rackName: rackname,
        connection: {
          type: 'vnc'
        }
      };
      return encryptPayload(guacConnectPayload);
    }
    return null;
  };

  const updateGuacView = (guacDisplay) => {
    guacDisplay.scale(calculateScaleFactor());
    const guacView = document.getElementById('vsm');
    // check if guac view already exists, if so, just replace it instead of appending
    if (guacView.firstChild) {
      guacView.replaceChild(guacDisplay.getElement(), guacView.firstChild);
    } else {
      guacView.appendChild(guacDisplay.getElement());
    }
  };

  const initializeVSM = async () => {
    const guacTunnel = new Guacamole.WebSocketTunnel(tunnelWsAddress);
    const guacClient = new Guacamole.Client(guacTunnel);
    const guacDisplay = guacClient.getDisplay();

    // add callbacks for the client
    guacClient.onerror = (error) => {
      console.error(error);
    };

    // Disconnect on close
    window.onunload = () => {
      guacClient.disconnect();
    };

    // resize the guacamole display depending on the screen size
    updateGuacView(guacDisplay);
    window.onresize = () => {
      updateGuacView(guacDisplay);
    };
    const payload = await getEncryptedPayload();
    guacClient.connect(`token=${payload}`);

    addMouseIntegration(guacClient);
    addKeyboardIntegration(guacClient);
  };

  useEffect(() => {
    initializeVSM();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <Box sx={styles.background}>
      <Box sx={styles.vsmContainer}>
        <Box sx={styles.border} />
        <Stack alignItems="center" justifyContent="center" id="container" sx={styles.vsm}>
          <div id="vsm" />
        </Stack>
        <Stack alignItems="center" justifyContent="center" sx={styles.border}>
          <Box component="img" src="/static/pac-white-logo.jpeg" sx={styles.panasonicLogo} />
        </Stack>
      </Box>
    </Box>
  );
}
