/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */

import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// material
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Select,
  Divider,
  IconButton,
  MenuItem,
  FormHelperText,
  InputLabel,
  Button,
  Grid,
  Checkbox,
  Typography,
  List,
  ListItemButton,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
// icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { boardIDValues, SmartMonitorOptions } from './SmartMonitorOptions';
import { diskSizes, LRUSize } from './LRUSize';
import PersistData from './PersistData';
import InfoToolTip from '../../common/InfoToolTip';
import { useIsFeatureFlagEnabled } from '../../../utils/FeatureFlags';

const systemTypes = [
  { value: '', label: 'None' },
  { value: 'eXW', label: 'eXW' },
  { value: 'eXCi', label: 'eXCi' },
  { value: 'eXCs', label: 'eXCs' },
  { value: 'eX1', label: 'eX1' }
];

const lruLabels = {
  is: 'IS (Type: 372 Subtype: 1)',
  next_is: 'NEXT IS (Type: 372/373, Subtype: 3)',
  ex3_nc: 'eX3 NC (Type: 370 Subtype: 1)',
  next_nc: 'NEXT NC (Type: 370 Subtype: 2)',
  next_cs4: 'NEXT CS4 (Type: 336 Subtype: 3)',
  fs05: 'FS05 (Type: 193 Subtype: 5)',
  cs4: 'CS4 (Type: 336 Subtype: 2)',
  bc03: 'BC03 (Type: 340 Subtype: 4)',
  smartmonitor: '[BETA] NEXT Smart Monitor (Type: 196)'
};

const defaultLrus = {
  is: {
    enabled: false,
    value: 'is',
    label: lruLabels.is,
    units: { 0: true, 1: false },
    cpu: 2000,
    memory: 2000,
    persistData: 'true',
    size: diskSizes[0],
    diskSize: diskSizes[0]
  },
  next_is: {
    enabled: false,
    value: 'next_is',
    label: lruLabels.next_is,
    units: { 0: true, 1: false },
    cpu: 2000,
    memory: 2000,
    persistData: 'true',
    size: diskSizes[0],
    diskSize: diskSizes[0]
  },
  ex3_nc: {
    enabled: false,
    value: 'ex3_nc',
    label: lruLabels.ex3_nc,
    units: { 0: true, 1: false },
    cpu: 2000,
    memory: 2000,
    persistData: 'true',
    size: diskSizes[0],
    diskSize: diskSizes[0]
  },
  next_nc: {
    enabled: false,
    value: 'next_nc',
    label: lruLabels.next_nc,
    units: { 0: true, 1: false },
    cpu: 2000,
    memory: 2000,
    persistData: 'true',
    size: diskSizes[0],
    diskSize: diskSizes[0]
  },
  next_cs4: {
    enabled: false,
    value: 'next_cs4',
    label: lruLabels.next_cs4,
    units: { 0: true, 1: false, 2: false, 3: false },
    cpu: 2000,
    memory: 2000,
    persistData: 'true',
    size: diskSizes[0],
    diskSize: diskSizes[0]
  },
  fs05: {
    enabled: false,
    value: 'fs05',
    label: lruLabels.fs05,
    units: { 0: false, 1: false, 2: false, 8: false },
    cpu: 2000,
    memory: 2000,
    persistData: 'true',
    system_type: 'eXW',
    size: diskSizes[0],
    diskSize: diskSizes[0]
  },
  cs4: {
    enabled: false,
    value: 'cs4',
    label: lruLabels.cs4,
    units: { 0: true, 1: false, 2: false, 3: false },
    cpu: 2000,
    memory: 2000,
    persistData: 'true',
    size: diskSizes[0],
    diskSize: diskSizes[0]
  },
  smartmonitor: {
    enabled: false,
    value: 'smartmonitor',
    label: lruLabels.smartmonitor,
    cpu: 4000,
    memory: 4000,
    persistData: 'true',
    config: {},
    size: diskSizes[0],
    diskSize: diskSizes[0]
  },
  bc03: {
    enabled: false,
    value: 'bc03',
    label: lruLabels.bc03,
    cpu: 2000,
    memory: 2000,
    persistData: 'true',
    size: diskSizes[0],
    diskSize: diskSizes[0]
  }
};

const possibleLRUCombinations = [
  { fs05: { units: ['0'], system_types: ['eXW'] }, smartmonitor: {} },
  {
    bc03: {},
    fs05: { units: ['8'], system_types: ['eXCs'] },
    smartmonitor: {}
  },
  { is: {}, next_is: {}, next_cs4: {}, cs4: {}, fs05: {}, smartmonitor: {} },
  { ex3_nc: {}, next_nc: {}, next_cs4: {}, cs4: {}, fs05: {}, smartmonitor: {} }
];

// list of possible units given a specified system type
const validUnitCombinations = {
  None: ['0', '1'],
  eXW: ['0'],
  eXCi: ['8'],
  eXCs: ['8'],
  eX1: ['0', '1', '2']
};

function parseUnitsFromRack(type, rackUnits) {
  // parses units from rack object to the format of units for this component
  const units = { ...defaultLrus[type].units };
  if (rackUnits) {
    rackUnits.forEach((unit) => {
      units[unit] = true;
    });
  }
  return units;
}

function parseResourceFromRack(lru, resourceType, rackResources) {
  // parses resource limits from rack object to the format of resource limits for this component
  if (rackResources) {
    let splitChar = 'm';
    if (resourceType === 'memory') {
      splitChar = 'M';
    }
    return parseInt(rackResources.limits[resourceType].split(splitChar)[0], 10);
  }
  return defaultLrus[lru][resourceType];
}

function getAvailableLRUs(lrus, bc03Enabled, ex3ncEnabled, nextisEnabled) {
  // get available lrus for LRU Type dropdown
  const availableLRUs = new Set();
  // get the list of added lrus
  const addedLRUS = getAddedLRUs(lrus);

  // check every combination of lrus and see if the added lrus are allowed in the combination
  possibleLRUCombinations.forEach((combination) => {
    if (
      addedLRUS.every((lru) => {
        // check if the lru is in the combination
        if (!(lru in combination)) {
          return false;
        }
        // check if the lru's units are allowed in the combination
        if ('units' in combination[lru]) {
          // get the enabled units of the current lru
          const enabledUnits = Object.keys(lrus[lru].units).filter((u) => lrus[lru].units[u]);
          if (!enabledUnits.every((unit) => combination[lru].units.includes(unit))) {
            return false;
          }
        }
        // check if the lru's system type is allowed in the combination
        if ('system_types' in combination[lru]) {
          if (!combination[lru].system_types.includes(lrus[lru].system_type)) {
            return false;
          }
        }
        return true;
      })
    ) {
      // add all of the lrus of the combination to the available LRUs set if it wasn't in the addedLRUs already
      Object.keys(combination).forEach((lru) => {
        if (!addedLRUS.includes(lru)) {
          availableLRUs.add(lru);
        }
      });
    }
  });
  // remove BC03 if feature gate not enabled
  if (!bc03Enabled) {
    availableLRUs.delete('bc03');
  }
  // remove Next-IS if feature gate not enabled
  if (!nextisEnabled) {
    availableLRUs.delete('next_is');
  }
  // remove ex3 NC if feature gate not enabled
  if (!ex3ncEnabled) {
    availableLRUs.delete('ex3_nc');
  }

  // return a sorted list of the LRUs
  return Array.from(availableLRUs).sort();
}

function getAddedLRUs(lrus) {
  // returns a list of added LRUs
  return Object.keys(lrus).filter((lru) => lrus[lru].enabled);
}

function removeAddedLRUs(lrus) {
  // set all lrus to be disabled
  Object.keys(lrus).forEach((lru) => {
    lrus[lru].enabled = false;
  });
}

function clearUnits(lru) {
  // clears the units of a given lru
  Object.keys(lru.units).forEach((unit) => {
    lru.units[unit] = false;
  });
}
function addLRUHandler(lrus, modifiedLRU) {
  // this function updates the other lrus values depending on what lru is being added.
  if (modifiedLRU === 'bc03') {
    lrus.fs05.system_type = 'eXCs';
    lrus.fs05.enabled = false;
    clearUnits(lrus.fs05);
  }
}

function removeLRUHandler(lrus, modifiedLRU) {
  // this function updates the other lrus values depending on what lru is being removed.

  const addedLRUS = getAddedLRUs(lrus);
  // check if only remaining added LRU is a smartmonitor and if so, clear all enabled LRUs
  if (addedLRUS.length === 1 && addedLRUS[0] === 'smartmonitor') {
    lrus.smartmonitor.enabled = false;
  }
  if (modifiedLRU === 'bc03') {
    lrus.fs05.system_type = 'eXW';
    clearUnits(lrus.fs05);
    // removes all lrus since there might be an FS or another LRU that is misconfigured
    removeAddedLRUs(lrus);
  }
}

LRUInfo.propTypes = {
  updateRack: PropTypes.func,
  rack: PropTypes.object,
  setNextButtonDisabled: PropTypes.func
};

export default function LRUInfo({ rack, updateRack, setNextButtonDisabled }) {
  const parseLrusFromRack = () => {
    // parse out the lrus from the rack object
    const newLrus = { ...defaultLrus };
    Object.entries(rack.rack.lrus).forEach(([lru, values]) => {
      newLrus[lru] = {
        enabled: values.enabled,
        value: lru,
        label: lruLabels[lru],
        units: parseUnitsFromRack(lru, values.units),
        cpu: parseResourceFromRack(lru, 'cpu', values.resources),
        memory: parseResourceFromRack(lru, 'memory', values.resources),
        persistData: values.persistData.toString(),
        size: values.size,
        diskSize: values.diskSize,
        config: values.config
      };
      // add system_type field to LRU object if lru is fs05
      if (lru === 'fs05') {
        newLrus[lru].system_type = 'eXW';
      }
    });
    return newLrus;
  };
  const [lrus, updateLRUs] = useState(parseLrusFromRack);
  const [currentLRU, updateCurrentLRU] = useState(lrus.is);
  // used to enable/disable lru type dropdown and add LRU button
  const [fieldsDisabled, setFieldsDisabled] = useState(false);
  // used to enable/disable only the add LRU button
  const [addLRUDisabled, setAddLRUDisabled] = useState(false);
  const bc03Enabled = useIsFeatureFlagEnabled('bc03');
  const ex3ncEnabled = useIsFeatureFlagEnabled('ex3_nc');
  const nextisEnabled = useIsFeatureFlagEnabled('next_is');

  const setCurrentLRU = (event) => {
    lrus[currentLRU.value] = currentLRU;
    // Save state of currentLRU to master list of LRUs
    updateLRUs(lrus);
    // Update current LRU to reflect the newly selected type
    updateCurrentLRU(lrus[event.target.value]);
  };

  const setLRUUnits = (event) => {
    // update units for lru
    const s = { ...currentLRU };
    s.units[event.target.name] = event.target.checked;
    updateCurrentLRU(s);
  };

  const setLRUSystemType = (event) => {
    // clear any existing units set for fs05
    const s = { ...currentLRU };
    Object.keys(s.units).forEach((unit) => {
      s.units[unit] = false;
    });

    // update system type for fs05
    s.system_type = event.target.value;
    updateCurrentLRU(s);
  };

  const setLRUPersist = (event) => {
    // update persistence for LRU
    const s = { ...currentLRU };

    s.persistData = event.target.value;
    updateCurrentLRU(s);
  };

  useEffect(() => {
    validateInputs();
    const nextLRU = getFirstDisabledLRU();
    if (nextLRU) {
      updateCurrentLRU(nextLRU);
    } else {
      setFieldsDisabled(true);
      setAddLRUDisabled(true);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const validateCurrentLRU = () => {
    const excludedLRUS = ['bc03', 'smartmonitor'];
    // check the following conditions and if true disable the ability to add LRU
    // 1. are there no added LRUs and is the current LRU selected a smartmonitor
    // 2. are there no units selected for the current LRU (assuming the LRU type has units)
    if (
      (!anyLRUAdded() && currentLRU.value === 'smartmonitor') ||
      (!Object.values(currentLRU.units).some(Boolean) && !excludedLRUS.includes(currentLRU.value))
    ) {
      // prevent user from being able to add the current LRU
      setAddLRUDisabled(true);
    } else {
      setAddLRUDisabled(false);
    }
  };

  const validateInputs = () => {
    if (!anyLRUAdded()) {
      setNextButtonDisabled(true);
    } else {
      setNextButtonDisabled(false);
    }
  };

  const addLRU = () => {
    const myLRUS = { ...lrus };
    myLRUS[currentLRU.value].enabled = true;

    // Adding a new LRU could cause the default values of another LRU to be modified as a result
    addLRUHandler(myLRUS, currentLRU.value);
    updateLRUs(myLRUS);
    validateInputs();

    const nextLRU = getFirstDisabledLRU();
    if (nextLRU) {
      updateCurrentLRU(nextLRU);
    } else {
      setFieldsDisabled(true);
      setAddLRUDisabled(true);
    }
  };

  const getFirstDisabledLRU = () => {
    // get the list of possible lru types first
    const lruTypes = getAvailableLRUs(lrus, bc03Enabled, ex3ncEnabled, nextisEnabled);
    for (let i = 0; i < lruTypes.length; i += 1) {
      if (!lrus[lruTypes[i]].enabled) {
        return lrus[lruTypes[i]];
      }
    }
    return null;
  };

  const anyLRUAdded = () => {
    let lruAdded = false;
    Object.values(lrus).forEach((lru) => {
      if (lru.enabled === true) {
        lruAdded = true;
      }
    });
    return lruAdded;
  };

  const removeLRU = (event, lru) => {
    event.stopPropagation();
    const myLRUS = { ...lrus };
    myLRUS[lru].enabled = false;
    clearUnits(myLRUS[lru]);

    removeLRUHandler(myLRUS, lru);
    updateLRUs(myLRUS);
    validateInputs();
    if (getFirstDisabledLRU()) {
      setFieldsDisabled(false);
      setAddLRUDisabled(false);
    }
  };

  const getValidSystemTypes = (systemTypes, lrus) => {
    // if no LRUs have been added yet and current LRU is FS05, only allowed system types are eXW
    if (!anyLRUAdded() && currentLRU.value === 'fs05') {
      return [{ value: 'eXW', label: 'eXW' }];
    }
    if (lrus.bc03.enabled && currentLRU.value === 'fs05') {
      return [{ value: 'eXCs', label: 'eXCs' }];
    }
    return systemTypes;
  };

  const isUnitDisabled = (unit) => {
    // only LRU with units requiring disabling is fs05
    if (currentLRU.value !== 'fs05') {
      return false;
    }

    // if system type is undefined or empty, replace with 'None'
    let systemType = currentLRU.system_type;
    if (systemType == null || systemType === '') {
      systemType = 'None';
    }
    // check whether valid units include the specified unit #
    return !validUnitCombinations[systemType].includes(unit);
  };

  useEffect(() => {
    const updateRackAllLru = () => {
      const rackCopy = { ...rack };
      Object.keys(lrus).forEach((l) => {
        const lru = lrus[l];
        const newRackUpdate = {
          persistData: lru.persistData,
          enabled: lru.enabled,
          units: Object.keys(lru.units).filter((k) => lru.units[k]),
          size: lru.size,
          diskSize: lru.diskSize,
          resources: {
            limits: {
              cpu: `${lru.cpu}m`,
              memory: `${lru.memory}M`
            }
          },
          config: lru.config
        };
        if ('system_type' in lru && lru.system_type !== '') {
          newRackUpdate.system_type = lru.system_type;
        }
        rackCopy.rack.lrus[lru.value] = newRackUpdate;
      });
      updateRack(rackCopy);
    };

    updateRackAllLru();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [lrus]);

  useEffect(() => {
    const myLRUS = { ...lrus };
    myLRUS[currentLRU.value] = currentLRU;
    updateLRUs(myLRUS);
    validateInputs();
    validateCurrentLRU();

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [currentLRU]);

  useEffect(() => {
    validateInputs();
    validateCurrentLRU();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const getLruPrimaryDetails = (lru) => {
    if (lru === 'smartmonitor') {
      return `NEXT Smart Monitor (Type: 196, Subtype: ${lrus[lru].config.subtype})`;
    }
    return lrus[lru].label;
  };

  const getLruSecondaryDetails = (lru) => {
    // retrieves the secondary details string for an LRU.
    // The details will be the enabled units for normal LRUs and smart monitor configs if it's a smart monitor
    if (lru === 'smartmonitor') {
      return `IP: ${lrus.smartmonitor.config.ip}, Board: ${
        boardIDValues[lrus.smartmonitor.config.boardId].label
      }`;
    }
    if (lru === 'bc03') {
      return 'Only GCS BC03 Standalone configuration is currently supported';
    }
    if (lru === 'next_is') {
      const units = Object.keys(lrus[lru].units).filter((unit) => lrus[lru].units[unit]);
      return `Units: ${units.join(',')}, Includes ARM secondary processor (373_3)`;
    }
    // check if lru has units
    if (Object.keys(lrus[lru].units).length > 0) {
      const units = Object.keys(lrus[lru].units).filter((unit) => lrus[lru].units[unit]);
      return `Units: ${units.join(',')}`;
    }
    return '';
  };

  const availableLRUs = getAvailableLRUs(lrus, bc03Enabled, ex3ncEnabled, nextisEnabled);

  return (
    <Grid container item spacing={2} ml={2} mt={1} mb={2} xs={12}>
      <Grid container spacing={2} item xs={12} sm={8} alignItems="flex-start">
        <Grid container spacing={2} item xs={12}>
          <Grid item xs={12}>
            <Typography variant="h6">LRU Type</Typography>
          </Grid>
          <Grid item ml={2} xs={12}>
            <FormControl>
              <InputLabel>LRU</InputLabel>
              <Select
                value={currentLRU.value}
                onChange={setCurrentLRU}
                disabled={fieldsDisabled}
                label="LRU Type"
              >
                {availableLRUs.map((lru) => (
                  <MenuItem key={lru} value={lru}>
                    {lrus[lru].label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>The type/subtype of LRU you want to add to your rack</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        {availableLRUs.length > 0 ? (
          <>
            <Grid container item spacing={2} xs={12}>
              <Grid item xs={12}>
                <Typography variant="h6">LRU Options</Typography>
              </Grid>
              <Grid container item ml={2} xs={12}>
                {Object.keys(currentLRU.units).length > 0 ? (
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      Units
                      <InfoToolTip title="Specifying more unit numbers will deploy more LRUs of the same type." />
                    </Typography>
                    <FormGroup row>
                      {Object.keys(currentLRU.units).map((k, i) => (
                        <FormControlLabel
                          key={i}
                          checked={currentLRU.units[k]}
                          disabled={isUnitDisabled(k)}
                          control={<Checkbox name={k.toString()} />}
                          onChange={setLRUUnits}
                          label={k}
                        />
                      ))}
                    </FormGroup>
                  </Grid>
                ) : null}
                {currentLRU.value === 'fs05' ? (
                  <Grid item pt={2} xs={12}>
                    <FormControl>
                      <Typography variant="subtitle1" sx={{ mb: 1 }}>
                        System Type
                      </Typography>
                      <Select
                        displayEmpty
                        labelId="system-type-label"
                        id="system-type-selector"
                        value={currentLRU.system_type}
                        onChange={setLRUSystemType}
                      >
                        {getValidSystemTypes(systemTypes, lrus).map((type) => {
                          return (
                            <MenuItem key={type.value} value={type.value}>
                              {type.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText>The system type for your FS05 unit.</FormHelperText>
                    </FormControl>
                  </Grid>
                ) : null}
                {currentLRU.value === 'smartmonitor' ? (
                  <SmartMonitorOptions
                    lru={currentLRU}
                    updateLRU={updateCurrentLRU}
                    setFieldsDisabled={setFieldsDisabled}
                  />
                ) : null}
              </Grid>
            </Grid>
            <Grid container item spacing={2} xs={12}>
              <LRUSize updateCurrentLRU={updateCurrentLRU} currentLRU={currentLRU} />
            </Grid>
            <Grid container item spacing={2} sm={12}>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    sx={{ paddingLeft: 0 }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography variant="h6">Advanced Settings</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid item xs={12}>
                      <PersistData currentLRU={currentLRU} setLRUPersist={setLRUPersist} />
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </>
        ) : null}
      </Grid>
      <Grid container item xs={12} sm={4}>
        <Grid item sm={12}>
          <Typography variant="h6" mb={1}>
            Added LRU
          </Typography>
          <List component="nav" dense>
            {getAddedLRUs(lrus).map((lru, index) => {
              return (
                <ListItemButton
                  key={lru}
                  divider
                  selected={selectedIndex === index}
                  onClick={(event) => handleListItemClick(event, index)}
                >
                  <ListItemText
                    primary={getLruPrimaryDetails(lru)}
                    secondary={getLruSecondaryDetails(lru)}
                    sx={{ '& p': { fontSize: '9pt' } }}
                  />
                  <IconButton edge="end" onClick={(event) => removeLRU(event, lru)}>
                    <HighlightOffOutlinedIcon />
                  </IconButton>
                </ListItemButton>
              );
            })}
            <Divider />
            <ListItem>
              <Button
                fullWidth
                variant="contained"
                onClick={addLRU}
                disabled={addLRUDisabled}
                startIcon={<AddCircleOutlineRoundedIcon />}
              >
                Add LRU
              </Button>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
}
