import axios from 'axios';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { IconButton, Tooltip, Button } from '@material-ui/core';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { setOpenSnackbar } from '../../../store/slices/snackbarSlice';
import ConfirmDialog from '../../ConfirmDialog';
import { getIDToken } from '../../authentication/login/amplify';

DeleteRackButton.propTypes = {
  type: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  rack: PropTypes.string,
  environment: PropTypes.string,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
    PropTypes.func,
    PropTypes.object
  ]),
  disabled: PropTypes.bool
};

DeleteRackButton.defaultProps = {
  type: 'text',
  variant: 'contained',
  color: 'primary'
};

export default function DeleteRackButton({
  type,
  variant,
  color,
  rack,
  environment,
  sx = [],
  disabled
}) {
  const dispatch = useDispatch();
  const userIdentity = useSelector((state) => state.identity.value);
  const [confirmDialogOpen, setDialogOpen] = useState(false);
  const openSnackbar = (payload) => dispatch(setOpenSnackbar(payload));

  const handleDialogCancel = () => {
    setDialogOpen(false);
  };
  const handleDialogConfirm = () => {
    deleteRack(rack, environment);
    setDialogOpen(false);
  };
  const handleDialogOpen = (event) => {
    event.stopPropagation();
    setDialogOpen(true);
  };

  const deleteRack = async (rack, rackEnvironment) => {
    console.debug(`Deleting ${rack} in environment ${rackEnvironment}`);
    const jwt = await getIDToken();
    const reqBody = {
      identity: userIdentity,
      releaseName: rack,
      environment: rackEnvironment
    };
    if (jwt) {
      // proceed to delete rack
      axios({
        method: 'delete',
        url: `${process.env.REACT_APP_API}/racks`,
        data: reqBody,
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` }
      })
        .then((response) => {
          console.debug(`successfully deleted rack: ${JSON.stringify(response.data)}`);
          openSnackbar({
            message: 'VRack delete request successful'
          });
        })
        .catch((error) => {
          console.error(error.message);
          openSnackbar({
            message: `VRack delete request failed: ${error.response.data.message}`,
            severity: 'error'
          });
        });
    } else {
      console.error('JWT has expired, cannot delete rack');
    }
  };
  return (
    <>
      <ConfirmDialog
        title={`Delete Rack (${rack})?`}
        content="Are you sure you wish to delete the rack?"
        onCancelHandler={handleDialogCancel}
        onConfirmHandler={handleDialogConfirm}
        isOpen={confirmDialogOpen}
      />
      {type === 'icononly' && (
        <Tooltip title="Delete Rack" placement="top">
          <IconButton
            variant={variant}
            onClick={handleDialogOpen}
            sx={{ ...sx }}
            disabled={disabled}
            color={color}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
      )}
      {type === 'text' && (
        <Tooltip title="Delete Rack" placement="top">
          <Button
            onClick={handleDialogOpen}
            variant={variant}
            sx={{ ...sx }}
            disabled={disabled}
            startIcon={<DeleteOutlineIcon />}
            color={color}
          >
            Delete
          </Button>
        </Tooltip>
      )}
    </>
  );
}
