/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
// material
import {
  Select,
  MenuItem,
  FormHelperText,
  InputLabel,
  Grid,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControl,
  Stack
} from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getIDToken } from '../../authentication/login/amplify';
import AutomaticRackStop from './AutomaticRackStop';

VRackInfo.propTypes = {
  updateRack: PropTypes.func,
  updateRackName: PropTypes.func,
  setNextButtonDisabled: PropTypes.func,
  rack: PropTypes.object,
  rackName: PropTypes.string
};

// the regex rule to validate the rack name. this ensures the name is all lowercase, starts & ends with alphanumeric, and contains no spaces
const rackNameMaxLength = 40;
const validRackName = new RegExp(`^(?![0-9]+$)(?!.*-$)(?!-)[a-z0-9-]{1,${rackNameMaxLength}}$`);
const rackNameInputHelperText = 'The name you wish to use for your VRack';
const rackNameInputSuccessHelperText = 'Rack name is valid';
const rackNameInputErrEmptyText = "Rack name can't be empty";
const rackNameTooLongErrHelperText = 'Rack name must contain no more than 40 characters';
const rackNameInputErrHelperText = `Rack name must contain only lowercase alphanumeric characters or '-', and start and end with an alphanumeric number.`;
const rackNameAlreadyExistsHelperText = `Rack with that name already exists`;

const styles = {
  textField: { minWidth: 400 },
  advancedSettings: {
    paddingLeft: 0
  }
};

export default function VRackInfo({
  rack,
  rackName,
  updateRack,
  updateRackName,
  setNextButtonDisabled
}) {
  const [vrackNameHelperText, setVrackNameHelperText] = useState(rackNameInputHelperText);
  const [vrackNameError, setVrackNameError] = useState(false);
  const [typingTimer, setTypingTimer] = useState(null);

  useEffect(() => {
    // disable the next button on initial page rendering
    setNextButtonDisabled(true);
    validateInputs();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const validateInputs = () => {
    if (rackName !== '') {
      validateRackName(rackName);
    }
  };

  const validateRackName = (rackName) => {
    // checks if it meets length criteria
    if (validRackName.test(rackName)) {
      // succeeds regex check for kubernetes naming convention
      setVrackNameHelperText(rackNameInputSuccessHelperText);
      setVrackNameError(false);
      updateRackName(rackName);
      setNextButtonDisabled(false);
    } else {
      // set err message if invalid
      if (rackName.length > rackNameMaxLength) {
        setVrackNameHelperText(rackNameTooLongErrHelperText);
      } else if (rackName === '') {
        setVrackNameHelperText(rackNameInputErrEmptyText);
      } else {
        setVrackNameHelperText(rackNameInputErrHelperText);
      }
      setVrackNameError(true);
    }
  };

  const handleEnvironmentChange = (event) => {
    updateRack((prevstate) => ({
      ...prevstate,
      environment: event.target.value
    }));
  };

  const checkRackExists = (jwt, rackName) => {
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}/racks/name/${rackName}`,
      headers: { Authorization: `Bearer ${jwt}` }
    });
  };

  const handleRacknameChange = (event) => {
    // Set button to disabled until validation is completed
    setNextButtonDisabled(true);
    const rackName = event.target.value;

    clearTimeout(typingTimer);
    setTypingTimer(
      setTimeout(async () => {
        const jwt = await getIDToken();
        if (jwt) {
          // Call checkRackExists then call remaining checks afterwards
          checkRackExists(jwt, rackName)
            .then(() => {
              // Rack Exists
              setVrackNameHelperText(rackNameAlreadyExistsHelperText);
              setVrackNameError(true);
            })
            .catch(() => {
              validateRackName(rackName);
            });
        } else {
          // Otherwise just perform name/empty name check
          validateRackName(rackName);
        }
      }, 500)
    );
  };

  return (
    <Grid data-testid="vrack-info" container ml={2} spacing={2} mt={1} mb={2} item xs={12}>
      <Grid item sm={12}>
        <FormControl sx={styles.textField}>
          <InputLabel id="environment-label">Environment</InputLabel>
          <Select
            labelId="environment-label"
            id="environment-selector"
            value={rack.environment}
            label="Environment"
            onChange={handleEnvironmentChange}
          >
            <MenuItem value="aws">AWS</MenuItem>
          </Select>
          <FormHelperText>The environment you wish to deploy your VRack</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item sm={12}>
        <TextField
          required
          label="VRack Name"
          defaultValue={rackName}
          helperText={vrackNameHelperText}
          onChange={handleRacknameChange}
          sx={styles.textField}
          error={vrackNameError}
        />
      </Grid>

      <Grid item sm={12}>
        <Accordion>
          <AccordionSummary sx={styles.advancedSettings} expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Advanced Settings</Typography>
          </AccordionSummary>
          <AccordionDetails sx={styles.advancedSettings}>
            <Stack spacing={3}>
              <AutomaticRackStop rack={rack} updateRack={updateRack} />
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
}
