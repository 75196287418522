import { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip, IconButton, Button } from '@material-ui/core';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import ConfirmDialog from '../../ConfirmDialog';
import { setOpenSnackbar } from '../../../store/slices/snackbarSlice';
import { getIDToken } from '../../authentication/login/amplify';

async function setRackState(rackName, environment, identity, running) {
  const jwt = await getIDToken();
  if (jwt) {
    const url = `${process.env.REACT_APP_API}/racks/running`;
    const bodyData = {
      environment,
      identity,
      releaseName: rackName,
      running
    };

    return axios({
      url,
      method: 'post',
      data: bodyData,
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${jwt}` }
    });
  }
  return Promise.reject(new Error('missing jwt'));
}

SetRackStateButton.propTypes = {
  rackDetails: PropTypes.object,
  variant: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
    PropTypes.func,
    PropTypes.object
  ])
};

SetRackStateButton.defaultProps = {
  variant: 'contained',
  type: 'text',
  disabled: false,
  rackDetails: { running: { state: 'running' } }
};

export default function SetRackStateButton({ rackDetails, variant, type, disabled, sx = [] }) {
  const dispatch = useDispatch();

  const [confirmDialogOpen, setDialogOpen] = useState(false);
  const identity = useSelector((state) => state.identity.value);
  const openSnackbar = (payload) => dispatch(setOpenSnackbar(payload));
  const getDesiredRunningState = () => !['', 'running'].includes(rackDetails.running.state);
  const getDesiredPowerState = () =>
    ['', 'running'].includes(rackDetails.running.state) ? 'off' : 'on';
  const getButtonColor = () => {
    switch (rackDetails.running.state) {
      case 'configuring':
        return 'warning';
      case 'running':
        return 'success';
      default:
        return 'secondary';
    }
  };

  const handleDialogCancel = () => {
    setDialogOpen(false);
  };

  const handleDialogOpen = (event) => {
    event.stopPropagation();
    setDialogOpen(true);
  };

  const handleDialogConfirm = () => {
    setRackState(rackDetails.name, rackDetails.cluster, identity, getDesiredRunningState())
      .then(() => {
        openSnackbar({
          message: `Request to set rack running state sent successfully!`
        });
      })
      .catch((error) => {
        openSnackbar({
          message: `${error.response.data.message}`,
          severity: 'error'
        });
      });
    setDialogOpen(false);
  };

  return (
    <>
      <ConfirmDialog
        title={`Turn Virtual Rack ${getDesiredPowerState()}?`}
        content={`This will set the rack VMs running state to ${getDesiredRunningState()}?`}
        onCancelHandler={handleDialogCancel}
        onConfirmHandler={handleDialogConfirm}
        isOpen={confirmDialogOpen}
      />
      <Tooltip title={`Turn ${getDesiredPowerState()} Vrack`} placement="top">
        <div>
          {type === 'icononly' && (
            <IconButton onClick={handleDialogOpen} sx={{ ...sx }} color={getButtonColor()}>
              <PowerSettingsNewOutlinedIcon />
            </IconButton>
          )}
          {type === 'text' && (
            <Button
              onClick={handleDialogOpen}
              variant={variant}
              disabled={disabled}
              sx={{ ...sx }}
              startIcon={<PowerSettingsNewOutlinedIcon />}
            >
              Power
            </Button>
          )}
        </div>
      </Tooltip>
    </>
  );
}
