import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

// material
import { FormControl, TextField, Grid, Typography } from '@material-ui/core';

import { getIDToken } from '../../authentication/login/amplify';

Mikrotik.propTypes = {
  updateRack: PropTypes.func,
  rack: PropTypes.object,
  setNextButtonDisabled: PropTypes.func
};
const mikrotikAPDefaultHelperText = 'VirtAP# assigned to use with your racks';
const mikrotikAPErrorEmptyHelperText =
  'VirtAP# cannot be empty. Please enter a VirtAP# or clear the UUID field';
const mikrotikAPErrorInvalidHelperText = 'AP# is invalid. Please enter a valid AP#';

const mikrotikAPErrorInvalidLengthHelperText = 'AP# must be 3 digits. Please enter a valid AP#';

const mikrotikUUIDDefaultHelperText = 'UUID assigned to use with your racks';
const mikrotikUUIDErrorEmptyHelperText =
  'UUID cannot be empty. Please enter a UUID or clear the VirtAP# field';
const mikrotikUUIDErrorInvalidHelperText =
  'UUID does not match the provided AP# details. Please enter a valid UUID';

const mikrotikUUIDErrorInvalidLengthHelperText =
  'UUID must be 36 characters long. Please enter a valid UUID';

const mikrotikImageRegistryPrefix =
  'gitdock.panasonic.aero:4567/automation/kubevirt/kubevirt-resources/vmidisks:mikrotik';
const mikrotikUUIDLength = 36;
const mikrotikAPLength = 3;

function GetMikrotikImage(uuid, ap) {
  return `${mikrotikImageRegistryPrefix}-ap${ap}-${uuid}`;
}

export const GetMikrotikAP = (image) => {
  const matches = image.match('ap(\\d+)');
  if (matches && matches.length === 2) {
    return matches[1];
  }
  return '';
};

const validateAP = async (ap) => {
  const jwt = await getIDToken();
  if (jwt) {
    return axios({
      method: 'get',
      url: `${process.env.REACT_APP_API}/mikrotik/${ap}`,
      headers: { Authorization: `Bearer ${jwt}` }
    });
  }

  return Promise.reject(new Error('missing jwt'));
};

export default function Mikrotik({ rack, updateRack, setNextButtonDisabled }) {
  //   // helper functions to extract values from the rack object for initialization
  const setMikrotikDetails = () => {
    // updates the rack object's gateway property with the new values
    const rackCopy = { ...rack };

    const enabled = mikrotikAP !== '' && mikrotikUUID !== '';
    rackCopy.rack.mikrotik.enabled = enabled;

    rackCopy.rack.mikrotik.uuid = mikrotikUUID;
    rackCopy.rack.mikrotik.image = GetMikrotikImage(mikrotikUUID, mikrotikAP);

    updateRack(rackCopy);
  };

  const updateAP = (event) => {
    setMikrotikAP(event.target.value);
  };

  const updateUUID = (event) => {
    setMikrotikUUID(event.target.value);
  };

  const [mikrotikAP, setMikrotikAP] = useState('');
  const [mikrotikUUID, setMikrotikUUID] = useState(
    rack.rack.mikrotik.uuid ? rack.rack.mikrotik.uuid : ''
  );
  const [mikrotikUUIDHelperText, updateMikrotikUUIDHelperText] = useState(
    mikrotikUUIDDefaultHelperText
  );
  const [mikrotikAPHelperText, updateMikrotikAPHelperText] = useState(mikrotikAPDefaultHelperText);

  const [mikrotikAPError, updateMikrotikAPError] = useState(false);
  const [mikrotikUUIDError, updateMikrotikUUIDError] = useState(false);

  useEffect(() => {
    if (rack.rack.mikrotik.image) {
      setMikrotikAP(GetMikrotikAP(rack.rack.mikrotik.image));
    }
    validateInputs();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    setMikrotikDetails();
    validateInputs();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [mikrotikAP, mikrotikUUID]);

  const validateInputs = () => {
    setNextButtonDisabled(false);

    if (mikrotikUUID === '' || mikrotikAP === '') {
      if (mikrotikUUID === '' && mikrotikAP !== '') {
        setNextButtonDisabled(true);
        updateMikrotikUUIDError(true);
        updateMikrotikUUIDHelperText(mikrotikUUIDErrorEmptyHelperText);
        return;
      }
      if (mikrotikAP === '' && mikrotikUUID !== '') {
        setNextButtonDisabled(true);
        updateMikrotikAPError(true);
        updateMikrotikAPHelperText(mikrotikAPErrorEmptyHelperText);
        return;
      }
    }

    if (mikrotikUUID !== '' && mikrotikUUID.length !== mikrotikUUIDLength) {
      setNextButtonDisabled(true);
      updateMikrotikUUIDError(true);
      updateMikrotikUUIDHelperText(mikrotikUUIDErrorInvalidLengthHelperText);
      return;
    }

    if (mikrotikAP !== '' && mikrotikAP.length !== mikrotikAPLength) {
      setNextButtonDisabled(true);
      updateMikrotikAPError(true);
      updateMikrotikAPHelperText(mikrotikAPErrorInvalidLengthHelperText);
      return;
    }

    // validate AP & UUID combination to confirm validity
    if (
      mikrotikAP !== '' &&
      mikrotikUUID !== '' &&
      mikrotikUUID.length === mikrotikUUIDLength &&
      mikrotikAP.length === mikrotikAPLength
    ) {
      validateAP(mikrotikAP)
        .then((response) => {
          if (response.data.uuid !== mikrotikUUID) {
            setNextButtonDisabled(true);
            updateMikrotikUUIDError(true);
            updateMikrotikUUIDHelperText(mikrotikUUIDErrorInvalidHelperText);
          }

          if (response.data.virtap !== parseInt(mikrotikAP, 10)) {
            setNextButtonDisabled(true);
            updateMikrotikAPError(true);
            updateMikrotikAPHelperText(mikrotikAPErrorInvalidHelperText);
          }
        })
        .catch(() => {
          setNextButtonDisabled(true);
          updateMikrotikAPError(true);
          updateMikrotikAPHelperText(mikrotikAPErrorInvalidHelperText);
        });
    }

    // Assuming none of previous validations have failed, reset all errors/helper text
    updateMikrotikAPError(false);
    updateMikrotikUUIDError(false);
    updateMikrotikAPHelperText(mikrotikAPDefaultHelperText);
    updateMikrotikUUIDHelperText(mikrotikUUIDDefaultHelperText);
  };

  return (
    <FormControl component="fieldset">
      <Typography variant="h6">MikroTik Configuration</Typography>
      <Typography variant="caption">
        These settings are for enabling a MikroTik. Please contact the Virtual Rack team to recieve
        a VirtAP# and UUID.
      </Typography>
      <Grid mt={1} sx={{ paddingLeft: 0 }} spacing={2} container item sm={12}>
        <Grid item xs={12} sm={6} md={5}>
          <TextField
            helperText={mikrotikAPHelperText}
            error={mikrotikAPError}
            value={mikrotikAP}
            onChange={updateAP}
            label="VirtAP #"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <TextField
            helperText={mikrotikUUIDHelperText}
            error={mikrotikUUIDError}
            value={mikrotikUUID}
            onChange={updateUUID}
            label="UUID"
            fullWidth
          />
        </Grid>
      </Grid>
    </FormControl>
  );
}
