import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Stack, AppBar, Toolbar, IconButton } from '@material-ui/core';
// components
import { useLocation } from 'react-router-dom';
import { MHidden } from '../../components/@material-extend';
import IdentityChooser from '../../components/nav/IdentityChooser';

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 3)
  }
}));

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const location = useLocation();
  // Test conditional render for if current path = '/racks/detail', if not then true
  const renderIdentity = !location.pathname.includes('/racks/details');
  console.log(renderIdentity);
  return (
    <RootStyle>
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ paddingY: '15px', width: '100%' }}
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {renderIdentity && <IdentityChooser />}
          {/* <LoginBox psopover /> */}
          {/* <AccountPopover /> */}
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
